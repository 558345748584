<template>
  <div class="row col-xl-12 mx-0 px-0">
    <div class="col-xl-8 pl-0 pr-2">
      <div class="card card-custom gutter-b">
        <div v-if="loadingComplete" class="card-header card-header-tabs-line">
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('SETUP.ROOMS.PREVIEW') }}
            </h3>
          </div>
        </div>
        <div class="card-body px-20">
          <b-spinner
            v-if="loadingComplete == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <div v-if="displayPlaceholder && loadingComplete">
            <span class="text-dark-65 font-weight-bold d-block py-3 text-center">
              {{ $t('SETUP.ROOMS.PLACEHOLDER') }}
            </span>
          </div>
          <ErrorBox :errors="projectRoomSetupErrors" @clear_errors="clearErrors">
          </ErrorBox>
          <div v-if="!displayPlaceholder" class="d-flex justify-content-center">
            <div ref="roomPreviewContainer" class="col-9">
              <div v-if="roomBuilder != null">
                <template v-for="(row, r) in roomBuilder">
                  <div
                    :key="r"
                    class="d-flex justify-content-center"
                    :class="{ 'room-container shadowed' : roomSettings.roomPadding == '0' }"
                  >
                    <template v-for="(room, ro) in row">
                      <RoomPreview
                        :key="ro"
                        :room="room"
                        :roomEdges="roomSettings.roomEdges"
                        :roomPadding="roomSettings.roomPadding"
                        :roomOverlay="roomSettings.roomOverlay"
                        :roomRows="roomBuilder.length"
                        :totalWidth="getContainerWidth()"
                        :existingRoom="getExistingRoom(room.position)"
                        :assetReservationData="assetReservationData"
                        :arDropzoneOptions="arDropzoneOptions"
                        @room_saved="onRoomSave"
                        @room_deleted="onRoomDeleted"
                      >
                      </RoomPreview>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadingComplete && !displayPlaceholder" class="card-footer d-flex justify-content-between my-0 py-4">
          <div>
            <a
              v-if="hasExistingSetup"
              href="javascript:void(0)"
              class="btn btn-outline-danger mr-2"
              @click="onClear()"
            >
              {{ $t('GENERAL.BUTTONS.DELETE') }}
            </a>
          </div>
          <a
            href="javascript:void(0)"
            class="btn btn-primary mr-2"
            @click="onSubmit()"
          >
            {{ $t('GENERAL.BUTTONS.SAVE') }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-xl-4 pr-0 pl-2">
      <RoomLayout
        :loadingComplete="loadingComplete"
        :roomLayout="roomLayout"
        @parent_changed="toggleParent"
        @child_changed="roomLayout.selectedChild = $event"
      >
      </RoomLayout>
      <RoomSettings
        v-if="!displayPlaceholder"
        :loadingComplete="loadingComplete"
        :roomSettings="{
          roomExclusivity: $v.roomSettings.roomExclusivity,
          roomPadding: $v.roomSettings.roomPadding,
          forumRoom: $v.roomSettings.forumRoom,
          roomEdges: $v.roomSettings.roomEdges,
          roomOverlay: $v.roomSettings.roomOverlay
        }"
        :forumOptions="forumOptions"
      >
      </RoomSettings>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { POPULATE_ROOM_SETUPS } from "@/core/services/store/system/system.module";
import {
  POPULATE_PROJECT_ROOM_SETUP_LIST,
  CREATE_PROJECT_ROOM_SETUP,
  UPDATE_PROJECT_ROOM_SETUP,
  DELETE_PROJECT_ROOM_SETUP,
  CLEAR_PROJECT_ROOM_SETUP_ERRORS
} from "@/core/services/store/project/project_room_setups.module";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";
import RoomLayoutHelper from "@/helpers/RoomLayoutHelper";

import ErrorBox from "@/view/components/ErrorBox";
import RoomLayout from "./rooms/RoomLayout";
import RoomSettings from "./rooms/RoomSettings";
import RoomPreview from "./rooms/RoomPreview";

export default {
  mixins: [validationMixin],

  name: "Rooms",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      },
      submissionStates: {
        submitting: false
      },
      roomLayout: {
        selectedParent: null,
        selectedChild: null
      },
      roomSettings: {
        roomExclusivity: "0",
        roomPadding: "1",
        roomEdges: "1",
        roomOverlay: "1",
        forumRoom: null
      },
      arDropzoneOptions: {
        supportedMimeTypes: {},
        supportedMimeSizes: {},
        supportedFileSize: 1600,
        supportedFileCount: 1,
        supportWebcam: 0,
        supportUploadButton: 1
      },
      rooms: []
    }
  },

  validations: {
    roomSettings: {
      roomExclusivity: { required },
      roomPadding: { required },
      roomEdges: { required },
      roomOverlay: { required },
      forumRoom: {}
    }
  },

  components: {
    ErrorBox,
    RoomLayout,
    RoomSettings,
    RoomPreview
  },

  mounted() {
    this.fetchSystemRoomSetups();
    this.fetchExistingRoomSetup();
  },

  methods: {
    fetchSystemRoomSetups: function() {
      this.$store.dispatch(POPULATE_ROOM_SETUPS, false).then(() => {
        this.pageLoader.componentsCompleted++;
      });
    },
    fetchExistingRoomSetup: function() {
      let payload = {
        projectId: this.projectInfo.id,
        data: {
          params: {
            projectRooms: "with",
            roomSetup: "with"
          }
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOM_SETUP_LIST, payload)
        .then(() => {
          if (this.projectRoomSetupList.length > 0) {
            this.roomLayout.selectedParent = this.projectRoomSetupList[0].room_setup.parent_layout;
            this.roomLayout.selectedChild = this.projectRoomSetupList[0].room_setup.id;
            this.roomSettings.roomExclusivity = this.projectRoomSetupList[0].rooms_exclusive;
            this.roomSettings.roomPadding = this.projectRoomSetupList[0].rooms_padded;
            this.roomSettings.roomEdges = this.projectRoomSetupList[0].rounded_edges;
            this.roomSettings.roomOverlay = this.projectRoomSetupList[0].rooms_full_overlay;
            this.rooms = this.projectRoomSetupList[0].project_rooms;
            let forumRoom = this.rooms.filter(function( room ) {
              return room.is_forum;
            });
            if (forumRoom.length > 0) {
              this.roomSettings.forumRoom = forumRoom[0].position
            }
          }
        })
        .finally(() => {
          this.pageLoader.componentsCompleted++;
        });
    },
    toggleParent: function(val) {
      if (this.roomLayout.selectedParent == val) {
        this.roomLayout.selectedParent = null;
      } else {
        this.roomLayout.selectedParent = val;
      }
    },
    getContainerWidth: function() {
      return this.$refs.roomPreviewContainer.clientWidth;
    },
    getExistingRoom: function(position) {
      let existingRoom = this.rooms.filter(function( room ) {
        return room.position == position;
      });

      return (existingRoom.length > 0) ? existingRoom[0] : {};
    },
    onClear: function() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.projectRoomSetupList[0].id
      };
      
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_ROOM_CHALLANGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_ROOM_CHALLANGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.DELETE_ROOM_CHALLANGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_PROJECT_ROOM_SETUP, payload).then(() => {
            this.roomLayout = {
              selectedParent: null,
              selectedChild: null
            },
            this.roomSettings = {
              roomExclusivity: "0",
              roomPadding: "1",
              roomEdges: "1",
              roomOverlay: "1",
              forumRoom: null
            };
            this.rooms = [];
            this.clearErrors();
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.DELETE_ROOM_SUCCESS.TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_ROOM_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_ROOM_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    },
    onSubmit: function() {
      this.clearErrors();

      if (this.rooms.length < this.selectedSystemRoomSetup.maximum_rooms) {
        window.alert("Please provide " + this.selectedSystemRoomSetup.maximum_rooms + " rooms");
        return false;
      }

      if (this.projectRoomSetupList.length > 0) {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitPost: function() {
      this.submissionStates.submitting = true;

      const self = this;
      let rooms = this.rooms.filter(function( room ) {
        return room.position <= self.selectedSystemRoomSetup.maximum_rooms;
      });

      rooms.forEach((room, index) => {
        (room.largePreview != undefined) ? delete rooms[index]['largePreview'] : null;
        (room.projectAsset != undefined) ? delete rooms[index]['projectAsset'] : null;
        (room.activities != undefined) ? delete rooms[index]['activities'] : null;
      });

      let payload = {
        projectId: this.projectInfo.id,
        data: {
          system_room_setup_id: this.layoutChild,
          forum: this.roomSettings.forumRoom,
          rounded_edges: this.roomSettings.roomEdges,
          rooms_exclusive: this.roomSettings.roomExclusivity,
          rooms_padded: this.roomSettings.roomPadding,
          rooms_full_overlay: this.roomSettings.roomOverlay,
          rooms: this.rooms
        }
      };

      this.$store
        .dispatch(CREATE_PROJECT_ROOM_SETUP, payload)
        .then(() => {
          this.$root.$bvToast.toast(`${this.$t("TOASTS.ADD_ROOM.MESSAGE")}`, {
            title: `${this.$t("TOASTS.ADD_ROOM.TITLE")}`,
            autoHideDelay: 5000,
            appendToast: false,
            variant: "success"
          });
        })
        .finally(() => {
          this.scrollTop();
          this.submissionStates.submitting = false;
        });
    },
    submitUpdate: function() {
      this.submissionStates.submitting = true;

      const self = this;
      let rooms = this.rooms.filter(function( room ) {
        return room.position <= self.selectedSystemRoomSetup.maximum_rooms;
      });

      rooms.forEach((room, index) => {
        (room.largePreview != undefined) ? delete rooms[index]['largePreview'] : null;
        (room.projectAsset != undefined) ? delete rooms[index]['projectAsset'] : null;
        (room.activities != undefined) ? delete rooms[index]['activities'] : null;
      });

      let payload = {
        projectId: this.projectInfo.id,
        id: this.projectRoomSetupList[0].id,
        data: {
          system_room_setup_id: this.layoutChild,
          forum: this.roomSettings.forumRoom,
          rounded_edges: this.roomSettings.roomEdges,
          rooms_exclusive: this.roomSettings.roomExclusivity,
          rooms_padded: this.roomSettings.roomPadding,
          rooms_full_overlay: this.roomSettings.roomOverlay,
          rooms: rooms
        }
      };

      this.$store
        .dispatch(UPDATE_PROJECT_ROOM_SETUP, payload)
        .then(() => {
          this.$root.$bvToast.toast(`${this.$t("TOASTS.EDIT_ROOM.MESSAGE")}`, {
            title: `${this.$t("TOASTS.EDIT_ROOM.TITLE")}`,
            autoHideDelay: 5000,
            appendToast: false,
            variant: "success"
          });
        })
        .finally(() => {
          this.scrollTop();
          this.submissionStates.submitting = false;
        });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_ROOM_SETUP_ERRORS);
    },
    onRoomSave: function(val) {
      let otherRooms = this.rooms.filter(function( room ) {
        return room.position != val.position;
      });
      otherRooms.push(val);
      this.rooms = otherRooms;
    },
    onRoomDeleted: function(val) {
      let otherRooms = this.rooms.filter(function( room ) {
        return room.position != val;
      });
      this.rooms = otherRooms;
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }) 
    },
  },

  watch: {
    layoutParent: {
      handler() {
        if (this.loadingComplete == true) {
          this.roomLayout.selectedChild = null;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectRoomSetupErrors",
      "projectRoomSetupList",
      "systemRoomSetups"
    ]),
    assetReservationData: function() {
      let data = {
        scope: "project",
        data: {
          directory: "room_images",
          projectId: this.projectInfo.id,
          new: 1
        }
      };
      return data;
    },
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    layoutParent: function() {
      return this.roomLayout.selectedParent;
    },
    layoutChild: function() {
      return this.roomLayout.selectedChild;
    },
    forumOptions: function() {
      const self = this;
      let options = this.rooms.filter(function( room ) {
        return room.position <= self.selectedSystemRoomSetup.maximum_rooms;
      });
      options.unshift({position: null, title: 'No Forum'});

      return options;
    },
    roomBuilder: function() {
      if (this.roomLayout.selectedChild == null) {
        return null;
      } else {
        return RoomLayoutHelper.returnRoomLayout(this.roomLayout.selectedChild);
      }
    },
    hasExistingSetup: function() {
      if (this.projectRoomSetupList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    displayPlaceholder: function() {
      if (this.hasExistingSetup == false && this.roomLayout.selectedParent == null && this.roomLayout.selectedChild == null) {
        return true;
      } else {
        return false;
      }
    },
    selectedSystemRoomSetup: function() {
      if (this.roomLayout.selectedChild == null) {
        return null;
      } else {
        const self = this;
        return this.systemRoomSetups.filter(function( systemRoomSetup ) {
          return systemRoomSetup.id == self.roomLayout.selectedChild;
        })[0];        
      }

    }
  }
}
</script>
