class RoomLayoutHelper {

  static returnRoomLayout(layoutId) {
    const layouts = {
      1: [
        [{ position: 1, roomMates: ["r","b"], span: 6 }, { position: 2, roomMates: ["l","b"], span: 6 }],
        [{ position: 3, roomMates: ["r","t"], span: 6 }, { position: 4, roomMates: ["l","t"], span: 6 }]
      ],
      2: [
        [{ position: 1, roomMates: ["r","b"], span: 4 }, { position: 2, roomMates: ["l","b","r"], span: 4 }, { position: 3, roomMates: ["l","b"], span: 4 }],
        [{ position: 4, roomMates: ["r","t"], span: 4 }, { position: 5, roomMates: ["l","t","r"], span: 4 }, { position: 6, roomMates: ["l","t"], span: 4 }]
      ],
      3: [
        [{ position: 1, roomMates: ["r","b"], span: 3 }, { position: 2, roomMates: ["l","b","r"], span: 3 }, { position: 3, roomMates: ["l","b","r"], span: 3 }, { position: 4, roomMates: ["l","b"], span: 3 }],
        [{ position: 5, roomMates: ["r","t"], span: 3 }, { position: 6, roomMates: ["l","t","r"], span: 3 }, { position: 7, roomMates: ["l","t","r"], span: 3 }, { position: 8, roomMates: ["l","t"], span: 3 }]
      ],
      4: [
        [{ position: 1, roomMates: ["r"], span: 6 }, { position: 2, roomMates: ["l"], span: 6 }]
      ],
      5: [
        [{ position: 1, roomMates: ["r"], span: 4 }, { position: 2, roomMates: ["l","r"], span: 4 }, { position: 3, roomMates: ["l"], span: 4 }]
      ],
      6: [
        [{ position: 1, roomMates: ["r"], span: 3 }, { position: 2, roomMates: ["l","r"], span: 3 }, { position: 3, roomMates: ["l","r"], span: 3 }, { position: 4, roomMates: ["l"], span: 3 }]
      ],
      7: [
        [{ position: 1, roomMates: ["r"], span: 2 }, { position: 2, roomMates: ["l","r"], span: 2 }, { position: 3, roomMates: ["l","r"], span: 2 }, { position: 4, roomMates: ["l","r"], span: 2 }, { position: 5, roomMates: ["l","r"], span: 2 }, { position: 6, roomMates: ["l"], span: 2 }]
      ],
      8: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t"], span: 12 }],
      ],
      9: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t","b"], span: 12 }],
        [{ position: 3, roomMates: ["t"], span: 12 }]
      ],
      10: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t","b"], span: 12 }],
        [{ position: 3, roomMates: ["t","b"], span: 12 }],
        [{ position: 4, roomMates: ["t"], span: 12 }]
      ],
      11: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t","b"], span: 12 }],
        [{ position: 3, roomMates: ["t","b"], span: 12 }],
        [{ position: 4, roomMates: ["t","b"], span: 12 }],
        [{ position: 5, roomMates: ["t"], span: 12 }]
      ],
      12: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t","b"], span: 12 }],
        [{ position: 3, roomMates: ["t","b"], span: 12 }],
        [{ position: 4, roomMates: ["t","b"], span: 12 }],
        [{ position: 5, roomMates: ["t","b"], span: 12 }],
        [{ position: 6, roomMates: ["t"], span: 12 }]
      ],
      13: [
        [{ position: 1, roomMates: ["b"], span: 12 }],
        [{ position: 2, roomMates: ["t","b"], span: 12 }],
        [{ position: 3, roomMates: ["t","b"], span: 12 }],
        [{ position: 4, roomMates: ["t","b"], span: 12 }],
        [{ position: 5, roomMates: ["t","b"], span: 12 }],
        [{ position: 6, roomMates: ["t","b"], span: 12 }],
        [{ position: 7, roomMates: ["t"], span: 12 }]
      ],
      14: [
        [{position: 1, roomMates: ["b","r"], span: 6}, {position: 2, roomMates: ["b","l"], span: 6}],
        [{position: 3, roomMates: ["t"], span: 12}],
      ],
      15: [
        [{position: 1, roomMates: ["b","r"], span: 4}, {position: 2, roomMates: ["b","l","r"], span: 4}, {position: 3, roomMates: ["b","l"], span: 4}],
        [{position: 4, roomMates: ["t"], span: 12}],
      ],
      16: [
        [{position: 1, roomMates: ["b","r"], span: 3}, {position: 2, roomMates: ["b","l","r"], span: 3}, {position: 3, roomMates: ["b","l","r"], span: 3}, {position: 4, roomMates: ["b","l"], span: 3}],
        [{position: 5, roomMates: ["t"], span: 12}],
      ],
      17: [
        [{position: 1, roomMates: ["b","r"], span: 6}, {position: 2, roomMates: ["b","l"], span: 6}],
        [{position: 3, roomMates: ["b","r","t"], span: 6}, {position: 4, roomMates: ["b","l","t"], span: 6}],
        [{position: 5, roomMates: ["t"], span: 12}],
      ],
      18: [
        [{position: 1, roomMates: ["b","r"], span: 4}, {position: 2, roomMates: ["b","l","r"], span: 4}, {position: 3, roomMates: ["b","l"], span: 4}],
        [{position: 4, roomMates: ["b","r","t"], span: 4}, {position: 5, roomMates: ["b","l","t","r"], span: 4}, {position: 6, roomMates: ["b","l","t"], span: 4}],
        [{position: 7, roomMates: ["t"], span: 12}],
      ],
      19: [
        [{position: 1, roomMates: ["b","r"], span: 3}, {position: 2, roomMates: ["b","l","r"], span: 3}, {position: 3, roomMates: ["b","l","r"], span: 3}, {position: 4, roomMates: ["b","l"], span: 3}],
        [{position: 5, roomMates: ["b","r","t"], span: 3}, {position: 6, roomMates: ["b","l","t","r"], span: 3}, {position: 7, roomMates: ["b","l","t","r"], span: 3}, {position: 8, roomMates: ["b","l","t"], span: 3}],
        [{position: 9, roomMates: ["t"], span: 12}],
      ]
    }

    return layouts[layoutId];
  }

}

export default RoomLayoutHelper;