<template>
  <div
    @click="$refs['roomPreviewModal' + room.position].show()"
    class="room-preview p-0" :class="classList"
  >
    <div
      :id="'roomPreviewPos' + room.position"
      class="card card-custom"
      style="background-size: cover; background-repeat: no-repeat"
      :style="styleList"  
    >
      <div
        class="card-body d-flex align-items-center justify-content-center p-2"
        v-b-tooltip.hover.top
        :title="calculatedRoomTitle"
      >
        <span v-if="noImage" class="font-size-h5">
          Room {{ room.position }}
        </span>
        <span
          v-if="!noImage && roomOverlay == '1'"
          class="room-title-overlay d-flex justify-content-center align-items-center"
        >
          {{ form.title }}
        </span>
      </div>
    </div>
    <b-modal size="lg" :ref="'roomPreviewModal' + room.position" :title="'Edit Room ' + room.position" hide-footer>
      <div class="row col-12 d-flex justify-content-start mt-5 mb-5 px-7 mx-0">
        <InputGeneric
          id="input-room-title"
          class="col-12"
          :model="$v.form.title"
          alignLabel="left"
          :placeholder="$t('FORMS.INPUTS.TITLE')"
          :label="$t('FORMS.INPUTS.TITLE')"
          :showInline="false"
          :submissionStates="submissionStates"
          :error="$t('FORMS.ERROR.TITLE')"
        >
        </InputGeneric>
      </div>
      <div class="row col-12 d-flex justify-content-start mt-5 mb-5 px-7 mx-0">
        <InputGenericRichText
          class="col-12"
          id="input-room-description"
          :label="$t('FORMS.INPUTS.DESCRIPTION')"
          :model="$v.form.description"
          :submissionStates="submissionStates"
          :error="$t('FORMS.ERROR.DESCRIPTION')"
        >
        </InputGenericRichText>
      </div>
      <div class="row col-12 d-flex justify-content-start mt-5 mb-5 px-7 mx-0">
        <IntegratedDropzone
          :ref="'room-dropzone-' + room.position"
          class="col-xl-12"
          :assetReservation="assetReservationData"
          :idx="room.position"
          :projectAssets="calculatedProjectAssets"
          :wholeWidth="true"
          :removable="true"
          @asset_reservation_created="form.image = $event"
          @asset_reservation_deleted="removeAssetReservation"
          @remove_project_asset="form.image_remove = $event"
        >
        </IntegratedDropzone>
      </div>
      <div class="row w-100 d-flex justify-content-between px-11 mx-0">
        <div>
          <a
            href="javascript:void(0)"
            class="btn btn-secondary mr-2"
            @click="onClose()"
          >
            {{ $t('GENERAL.BUTTONS.CANCEL') }}
          </a>
          <a
            v-if="existingRoom.position != undefined"
            href="javascript:void(0)"
            class="btn btn-outline-danger"
            @click="onDelete()"
          >
            {{ $t('GENERAL.BUTTONS.DELETE') }}
          </a>
        </div>
        <a
          href="javascript:void(0)"
          class="btn btn-primary"
          @click="onSubmit()"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericRichText from "@/view/components/form-inputs/GenericRichText";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

export default {
  mixins: [validationMixin],

  name: "RoomPreview",

  props: {
    room: { type: Object, required: true },
    roomEdges: { required: true },
    roomPadding: { required: true },
    roomOverlay: { required: true },
    roomRows: { type: Number, required: true },
    totalWidth: { type: Number, required: true },
    assetReservationData: { type: Object, required: true },
    arDropzoneOptions: { type: Object, required: true },
    existingRoom: { type: Object, required: true }
  },

  data() {
    return {
      form: {
        id: null,
        title: "",
        description: "",
        image: null,
        image_remove: null
      },
      submissionStates: {
        submitting: false
      },
      largePreview: null
    }
  },

  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      description: {},
      image: {
        required
      }
    }
  },

  components: {
    InputGeneric,
    InputGenericRichText,
    IntegratedDropzone
  },

  mounted() {
    if (this.existingRoom.position != undefined) {
      this.form.title = this.existingRoom.title;
      this.form.description = this.existingRoom.description;
      this.form.image = this.existingRoom.project_asset_id;
      this.form.id = this.existingRoom.id;

      if (this.existingRoom.largePreview != undefined) {
        this.largePreview = this.existingRoom.largePreview;
      }
    }
  },

  methods: {
    removeAssetReservation: function() {
      this.form.image_remove = this.form.image;
      this.form.image = null;
      this.previews = { previews: []};
    },
    onClose: function() {
      this.$v.form.$reset();
      this.$refs['roomPreviewModal' + this.room.position].hide()
    },
    onDelete: function() {
      this.largePreview = null;
      this.form.title = "";
      this.form.description = "";
      this.previews = { previews: []};
      this.form.image = null;
      this.$emit('room_deleted',this.room.position);
      this.onClose();
    },
    onSubmit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.largePreview = this.$refs['room-dropzone-' + this.room.position].previews[0];

      let data = {
        id: this.form.id,
        title: this.form.title,
        description: this.form.description,
        image: this.form.image,
        image_remove: this.form.image_remove,
        position: this.room.position,
        largePreview: this.largePreview
      }

      if (this.existingImage != null) {
        data.projectAsset = this.existingRoom.projectAsset;
      }

      this.$emit('room_saved',data);
      this.onClose();
    }
  },

  computed: {
    existingImage: function() {
      if (this.existingRoom.position == undefined) {
        return null;
      } else {
        return this.existingRoom.projectAsset;
      }
    },
    noImage: function() {
      if (this.largePreview == null && this.existingImage == null) {
        return true;
      } else {
        return false;
      }
    },
    classList: function() {
      let classArray = ['col-' + this.room.span];

      (this.roomEdges == "0") ? classArray.push('room-square') : classArray.push('room-rounded');
      (this.roomPadding == "1") ? classArray.push('padded-room') : classArray.push('no-padded-room');

      this.room.roomMates.forEach(roomMate => {
        classArray.push('room-rm-' + roomMate);
      });

      let classList = classArray.toString();
      return classList.replaceAll(',', ' ');
    },
    styleList: function() {
      let height = this.totalWidth / this.roomRows;
      height = 'height:' + height + 'px';
      
      if (this.largePreview != undefined && this.largePreview != null) {
        let background = "background: url('" + this.largePreview.dataURL + "');";
        return height + ";" + background;
      } else {
        if (this.existingImage != null) {
          let background = "background: url('" + this.existingImage.signedUrl + "');";
          return height + ";" + background;
        } else {
          return height;
        }
      }
    },
    calculatedRoomTitle: function() {
      if (this.roomOverlay == "1") {
        return "";
      } else {
        return this.form.title;
      }
    },
    calculatedProjectAssets: function() {
      if (this.existingImage == null) {
        return [];
      } else {
        return [this.existingImage];
      }
    }
  }
}
</script>