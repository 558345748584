<template>
  <div class="row mx-0">
    <div class="card card-custom gutter-b w-100">
      <div class="card-body px-15">
        <b-spinner
          v-if="loadingComplete == false"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <div v-else>
          <h6 class="mb-3">{{ $t('SETUP.ROOMS.LAYOUT') }}</h6>
          <div class="d-flex justify-content-start pt-5 pb-2">
            <template v-for="(parentRoomLayout, i) in parentRoomLayouts">
              <a :key="i" href="javascript:void(0)" @click="$emit('parent_changed',parentRoomLayout.id)">
                <span class="symbol symbol-50 rounded-0 mr-3">
                  <img
                    alt="Pic"
                    :src="'/media/room-layouts/' + parentRoomLayout.name + '.gif'"
                    class="rounded-0 p-1"
                    :class="{ 'border border-success' : roomLayout.selectedParent == parentRoomLayout.id }"
                  />
                </span>
              </a>
            </template>
          </div>
          <div v-if="childRoomLayouts.length > 0" class="d-flex justify-content-start pt-2 pb-5">
            <template v-for="(childRoomLayout, i) in childRoomLayouts">
              <a :key="i" href="javascript:void(0)" @click="$emit('child_changed',childRoomLayout.id)">
                <span class="symbol symbol-50 rounded-0 mr-3">
                  <img
                    alt="Pic"
                    :src="'/media/room-layouts/' + childRoomLayout.name + '.gif'"
                    class="rounded-0 p-1"
                    :class="{ 'border border-success' : roomLayout.selectedChild == childRoomLayout.id }"
                    v-b-tooltip.hover.bottom
                    :title="childRoomLayout.name"
                  />
                </span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RoomLayout",

  props: {
    loadingComplete: { type: Boolean, required: true },
    roomLayout: { type: Object, required: true }
  },

  computed: {
    ...mapGetters([
      "systemRoomSetups"
    ]),
    parentRoomLayouts: function() {
      return this.systemRoomSetups.filter(function( systemRoomSetup ) {
        return systemRoomSetup.is_parent == 1;
      });
    },
    childRoomLayouts: function() {
      if (this.roomLayout.selectedParent == null) {
        return [];
      } else {
        let selectedParent = this.roomLayout.selectedParent;
        return this.systemRoomSetups.filter(function( systemRoomSetup ) {
          return systemRoomSetup.parent_layout == selectedParent;
        });
      }
    }
  }
};
</script>
