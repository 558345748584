<template>
  <div class="row mx-0 room-settings">
    <div class="card card-custom gutter-b w-100">
      <div class="card-body px-15">
        <b-spinner
          v-if="loadingComplete == false"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <div v-else>
          <h6 class="mb-8">{{ $t('SETUP.ROOMS.SETTINGS') }}</h6>

          <!--start::Room Exclusivity-->
          <InputGenericCheckbox
            class="col-xl-12 mt-5 mb-2 px-0"
            id="input-exclusive"
            :label="$t('SETUP.ROOMS.OPTION_EXLCUSIVITY')"
            :model="roomSettings.roomExclusivity"
            v-b-tooltip.hover.right
            :title="$t('SETUP.ROOMS.OPTION_EXCLUSIVITY_PH')"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
          <!--end::Room Exclusivity-->

          <!--start::Room Padding-->
          <InputGenericCheckbox
            class="col-xl-12 mt-2 mb-2 px-0"
            id="input-padding"
            :label="$t('SETUP.ROOMS.OPTION_PADDING')"
            :model="roomSettings.roomPadding"
            v-b-tooltip.hover.right
            :title="$t('SETUP.ROOMS.OPTION_PADDING_PH')"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
          <!--end::Room Padding-->

          <!--start::Room Edges-->
          <InputGenericCheckbox
            class="col-xl-12 mt-2 mb-2 px-0"
            id="input-edges"
            :label="$t('SETUP.ROOMS.OPTION_ROUNDED_EDGES')"
            :model="roomSettings.roomEdges"
            v-b-tooltip.hover.right
            :title="$t('SETUP.ROOMS.OPTION_ROUNDED_EDGES_PH')"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
          <!--end::Room Edges-->

          <!--start::Room Overlay-->
          <InputGenericCheckbox
            class="col-xl-12 mt-2 mb-2 px-0"
            id="input-overlay"
            :label="$t('SETUP.ROOMS.OPTION_OVERLAY')"
            :model="roomSettings.roomOverlay"
            v-b-tooltip.hover.right
            :title="$t('SETUP.ROOMS.OPTION_OVERLAY_PH')"
            :submissionStates="submissionStates"
          >
          </InputGenericCheckbox>
          <!--end::Room Overlay-->

          <!--start::Forum Room-->
          <InputGenericDropdown
            id="input-forum"
            class="col-xl-12 mt-5 mb-5 px-0"
            :label="$t('SETUP.ROOMS.OPTION_FORUM')"
            :model="roomSettings.forumRoom"
            :options="forumOptions"
            optionLabel="title"
            value="position"
          >
          </InputGenericDropdown>
          <!--end::Forum Room-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";

export default {
  name: "RoomSettings",

  props: {
    loadingComplete: { type: Boolean, required: true },
    roomSettings: { type: Object, required: true },
    forumOptions: { type: Array, required: true }
  },

  components: {
    InputGenericCheckbox,
    InputGenericDropdown
  }
};
</script>
